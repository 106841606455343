import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'

Alpine.plugin(focus)

window.Alpine = Alpine

require('./alpine/store/keywordsStore');
require('./alpine/keywords-input');
require('./alpine/context-menu');
require('./alpine/store/themes');
require('./alpine/dropdownHover');
require('./alpine/dropdown');
require('./alpine/delete-model-toggler');
require('./alpine/trap');
require('./alpine/searchbar');
require('./alpine/toggler');

// Make slug from string
window.slugify = function(str) {
    str = str.replace(/^\s+|\s+$/g, '').toLowerCase();
    const from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;",
    to = "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
    for (let i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }
    // Remove invalid chars, whitespace to (-), collapse dashes
    str = str.replace(/[^a-z0-9 -]/g, '').replace(/\s+/g, '-').replace(/-+/g, '-');
    return str;
}

// CHECK IF IMAGE EXISTS
window.checkIfImageExists = function checkIfImageExists(url, callback) {
	const img = new Image();
	img.src = url;
	
	if (img.complete) {
	  callback(true);
	} else {
	  img.onload = () => {
		callback(true);
	  };
	  
	  img.onerror = () => {
		callback(false);
	  };
	}
  }

Alpine.start()