Alpine.data('searchbar', () => ({
	searchBar: false,
	
	focusSearchBar() {
		setTimeout(() => {
			this.$refs.q.focus()
		}, 100)
	},

	scrollHandler() {
		if (window.pageYOffset > 300) {
			this.backToTopBtn = true
		} else {
			this.backToTopBtn = false
		}	
	},

}))