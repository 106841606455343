Alpine.data('keywordsInput', (storeName = "keywords") => ({
	oldVal: '',
	allKeywords: [],

	init() {
		this.allKeywords.length = 0
	},

	//On key press, check for , or ;
	checkLetter() {
		txt = this.$refs.txtInput.value
		if(txt.trim().length = 1 && (txt.trim() === ',' || txt.trim() === ';')) {
			this.resetTextbox()
			return
		}

		// remove multiple space in words eg: this is    a lol => this is a lol
		txt = txt.replace(/  +/g, ' ');

		if(txt.length > 0){
			letter = txt.slice(-1);
			if(letter === ',' || letter === ';')	{
				word = txt.slice(0,-1);
				if(word.length > 0){
					this.addWord(word.trim());
					Alpine.store(storeName).text = this.getKeywords()
				}
			}
		}
		
	},

	// Ignore duplicate keywords
	// indexOf returns a number, representing the position where the specified searchvalue occurs for the first time, or -1 if it never occurs
	ignoreDuplicateKeywords(word) {
		isWordRepeated = this.allKeywords.indexOf(word.trim()) > -1
		this.resetTextbox()
		return isWordRepeated
	},

	//Add a keyword
	addWord(word) {
		if(word === undefined || word === '' || this.ignoreDuplicateKeywords(word) ){
			return;
		}
		this.allKeywords.push(word)

		xIcon = "<svg @click='deleteWord($el)' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' class='w-5 h-5 link-danger p-0 cursor-pointer' fill='currentColor'><path fill-rule='evenodd' d='M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z' clip-rule='evenodd' /></svg>"

		el = document.createElement("span")
		el.classList.add('flex', 'items-center', 'gap-4', 'bg-neutral-200', 'dark:bg-neutral-700', 'dark:text-neutral-300', 'rounded-md', 'px-2', 'py-1')
		el.innerHTML = "<span class='line-clamp-1'>" + word + "</span>" + xIcon

		this.$refs.cfKeywordsInput.insertBefore(el, this.$refs.txtInput)
		
		this.resetTextbox()
	},

	// reset textbox
	resetTextbox() {
		this.$refs.txtInput.value = ""
		this.$refs.txtInput.focus()
	},

	//Delete a keyword
	deleteWord(element)	{
		this.allKeywords.splice(this.allKeywords.indexOf(element.parentElement.firstElementChild.innerHTML), 1);
		element.parentElement.remove()
		Alpine.store(storeName).text = this.getKeywords()
	},

	//On focus out, add word
	addWordFromTextBox() {
		val = this.$refs.txtInput.value
		if(val !== undefined && val !== '') {
			this.addWord(val)
		}
	},

	getKeywords() {
		keywords = ""
		for (const x of this.allKeywords) { 
			keywords += x + ","
		}
		// Passing a negative number will remove starting from the end
		finalKeywords = keywords.slice(0, -1)
		return finalKeywords
	}

}))


