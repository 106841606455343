Alpine.store('themes', {

	changeTo(theme = 'light') {
		this.setThemeInLocalStorage(theme)
		this.changeThemes()
	},

	init() {
		this.changeThemes()
	},

	changeThemes() {
		// On page load or when changing themes, best to add inline in `head` to avoid FOUC
		if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
			document.documentElement.classList.add('dark')
		} else {
			document.documentElement.classList.remove('dark')
		}
	},

	setThemeInLocalStorage(theme = 'light')
	{
		if(theme == 'light'){
			// Whenever the user explicitly chooses light mode
			localStorage.theme = 'light'
		}
		else if(theme == 'dark') {					
			// Whenever the user explicitly chooses dark mode
			localStorage.theme = 'dark'
		}
		else if(theme == 'system') {
			// Whenever the user explicitly chooses to respect the OS preference
			localStorage.removeItem('theme')
		}
	},
})